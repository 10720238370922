<template>
  <div class="container" @keydown.enter.prevent="submit">
    <div class="mt-8 mb-8">
      <div class="row">
        <div class="col col-sm-12 col-md-8 mx-auto">
          <div class="mt-3 mb-3">
            <div
              class="alert alert-danger"
              role="alert"
              v-if="errors.process_country"
            >
              Could not get fields
            </div>
            <div
              class="alert alert-danger"
              role="alert"
              v-if="$store.getters.direct_search.error"
            >
              {{ $store.getters.direct_search.error }}
            </div>
            <div class="alert alert-danger" role="alert" v-if="errors.search">
              Oops! Something went wrong
            </div>

            <div
              v-if="errors.rejected"
              style="border: 1px solid red; padding: 15px; border-radius: 6px; text-align: center;"
            >
              <div v-if="errors.rejected" style="color: red; ">
                Error {{ errors.code }}: {{ errors.rejected }}
              </div>
              <div
                v-if="isTimer"
                style="display: flex; align-items: center; justify-content: center;"
              >
                <base-button
                  type="info"
                  size="small"
                  style="text-transform: capitalize; border-radius: 30px;"
                  @click="reSubmit()"
                  >Yes ({{ timerCount }} seconds remaining)</base-button
                >
                <div>
                  <a
                    v-bind:href="redirect_url"
                    target="_blank"
                    class="linkRedirect"
                    >View matching records</a
                  >
                </div>
              </div>
              <div
                v-if="is140"
                style="display: flex; align-items: center; justify-content: center; margin-top: 15px;"
              >
                <div>
                  <a
                    v-bind:href="redirect_url"
                    target="_blank"
                    class="linkRedirect"
                    >View matching records</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <form
          @submit.prevent="submit"
          @keydown.enter.prevent="submit"
          class="mx-auto col-md-6"
        >
          <div class="row">
            <div class="form-group col-sm-12">
              <label for="country">Country</label>
              <div class="directForm">
                <multi-select
                  id="country"
                  v-model="search_country"
                  :options="country_list"
                  :searchable="false"
                  :show-labels="false"
                  :max-height="200"
                  open-direction="below"
                  label="country_name"
                  track-by="record_number"
                  :clear-on-select="false"
                  @select="resetOnCountrySelect"
                  placeholder="Select country"
                ></multi-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12" v-if="fields.length">
              <label for="search_type">Search type</label>
              <div class="directForm">
                <multi-select
                  id="search_type"
                  v-model="search_type"
                  track-by="search_name"
                  label="search_name"
                  :options="fields"
                  :searchable="false"
                  :show-labels="false"
                  :clear-on-select="false"
                  @select="resetSearchFields"
                  placeholder="Select search type"
                >
                </multi-select>
              </div>
            </div>
          </div>
          <div class="row" v-if="fields.length && current_index >= 0">
            <div
              v-for="(field, index) in fields[current_index].fields"
              :key="index"
              class="col col-sm-12"
            >
              <div
                :class="field.field + ' otherField'"
                v-if="field.values.length == 0"
              >
                <label class="directLabel" :for="field.field">{{
                  formatLabel(field.field)
                }}</label>
                <base-input
                  :id="field.field"
                  :placeholder="formatLabel(field.field)"
                  v-model="
                    $store.state.direct_search.search.fields[field.field]
                  "
                ></base-input>
              </div>
              <div v-else>
                <label :for="field.field">{{ formatLabel(field.field) }}</label>
                <select
                  class="form-control"
                  id="exampleFormControlSelect1"
                  v-model="
                    $store.state.direct_search.search.fields[field.field]
                  "
                >
                  <option v-for="(option, index) in field.values" :key="index">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="processing" class="mt-3">
            <div class="text-center">
              <base-button size="lg" type="danger" @click="cancelSearch()"
                >Cancel <i class="fa fa-close"></i
              ></base-button>
            </div>
          </div>
          <div class="mt-3" v-else>
            <div class="text-center">
              <base-button
                :disabled="fields.length == 0 ? true : false"
                @click="submit()"
                type="info"
                size="lg"
                style="background: #066cd9; padding: 14px 24px; border-radius: 30px; border: none;"
                >Search <span class="fa fa-search"></span
              ></base-button>
            </div>
            <input type="submit" style="display: none" />
          </div>
        </form>
      </div>
      <div class="results">
        <div v-if="results.length != 0">
          <h1 class="display-4 mt-4 text-primary">Search Results</h1>
          <div
            class="result-list-item mb-3"
            v-for="(result, index) in results"
            :key="index"
          >
            <div>
              <h3>
                <router-link :to="showDetail(result, true)">{{
                  result["company_name"]
                }}</router-link>
              </h3>
              {{ result["country"] }}
            </div>
          </div>
        </div>
        <div
          v-else-if="
            results.length == 0 && $store.getters.direct_search.completed
          "
          class="text-center lead"
        >
          No records found matching your search query.
        </div>
        <div v-if="processing">
          <div class="alert alert-primary mt-3">
            <strong>Request is processing to fetch data</strong>
          </div>
        </div>
        <div style="margin-bottom: 20em"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import { ValidationObserver } from "vee-validate";
const MultiSelect = () =>
  import(/* webpackChunkName: "MultiSelect"*/ "vue-multiselect");
import Moment from "moment";
import randomstring from "randomstring";
export default {
  components: {
    MultiSelect
    // ValidationObserver,
  },
  data: function() {
    return {
      meta: {
        title: "Direct Search"
      },
      country_list: [],
      search: { country: "", fields: {} },
      isTimer: false,
      is140: false,
      timerCount: 30,
      timerEnabled: true,
      redirect_url: null,
      request_token: null,
      isResubmit: false,
      errors: {
        process_country: null,
        search: null,
        rejected: null,
        code: null
      },
      search_type: {}
    };
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else if (this.timerCount === 0) {
          this.isTimer = false;
          this.errors.rejected = null;
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  metaInfo() {
    return {
      title: this.meta.title
    };
  },
  mounted() {
    this.checkAuth();
    this.updateCountryList();
  },
  computed: {
    current_index() {
      let i = 0;
      for (i = 0; i < this.fields.length; ++i)
        if (this.fields[i].search_type == this.search_type.search_type)
          return i;
      return -1;
    },
    search_country: {
      get() {
        return this.$store.getters.direct_search.search.country;
      },
      set(data) {
        this.$store.state.direct_search.search.country = data;
        this.processCountry(data);
      }
    },
    processing() {
      return this.$store.getters.direct_search.processing;
    },
    country_list_selected: {
      get() {
        return this.store.getters.direct_search.country_list_selected;
      },
      set(data) {
        this.store.getters.direct_search.country_list_selected = data;
      }
    },
    fields() {
      return this.$store.getters.direct_search.fields;
    },
    search_fields() {
      return this.$store.getters.direct_search.search.fields;
    },
    results() {
      return this.$store.getters.direct_search_results;
    }
  },
  methods: {
    resetOnCountrySelect() {
      this.search_type = {};
      this.$store.state.direct_search.search.fields = {};
    },
    resetSearchFields() {
      this.$store.state.direct_search.search.fields = {};
    },
    play() {
      this.timerEnabled = true;
    },

    stop() {
      this.timerEnabled = false;
      this.timerCount = 0;
    },

    pause() {
      this.timerEnabled = false;
    },
    formatted_time(d) {
      return Moment(d).format("lll");
    },
    showDetail(data, linkOnly) {
      if (linkOnly) {
        return `/search/detail/${data.urn}-${data.country_code}`;
      }
      this.$router.push(`/search/detail/${data.urn}-${data.country_code}`);
    },
    clearErrors(fields = []) {
      if (fields.length == 0) {
        for (let e in this.errors) {
          this.errors[e] = null;
        }
      } else {
        fields.forEach(e => {
          this.errors[e] = null;
        });
      }
    },
    formatLabel(data) {
      let res = "";
      data = data.split("_");
      data.forEach(str => {
        res += str.charAt(0).toUpperCase() + str.slice(1);
        res += " ";
      });
      return res;
    },
    getSearchFields() {},
    sortCountryList() {
      this.country_list.sort((a, b) =>
        (a["country_name"] || "")
          .toString()
          .localeCompare((b["country_name"] || "").toString())
      );
    },
    updateCountryList() {
      this.$axios.get("search/country-list/").then(res => {
        this.country_list = res.data.filter(
          el => el.userapi_status === "AVAILABLE"
        );
        this.sortCountryList();
      });
    },
    checkAuth() {
      if (!this.$store.getters.auth) {
        return this.$router.push("/login?next=/direct-search");
      }
    },
    processCountry(country) {
      this.errors.process_country = null;
      if (country) {
        this.$axios
          .get(
            `search/direct/search-fields/?country_code=${this.search_country.record_number}`
          )
          .then(res => {
            this.$store.state.direct_search.fields = res.data;
          })
          .catch(err => {
            if (err.response) {
              this.$store.state.direct_search.fields = [];
              this.errors.process_country = err.response.data;
            }
          });
      } else {
        this.$store.commit("direct_search_key", { key: "fields", val: [] });
      }
      this.$store.commit("clear_direct_search");
    },
    get_url_params() {
      let url = `?`;
      const str = randomstring.generate({ length: 80 });
      url += `country_code=${this.search_country.record_number}`;
      url += `&search_type=${this.search_type.search_type}`;
      url += `&request_type=DIRECTSEARCH`;
      url += `&user_reference=${str}`;
      for (var item in this.search_fields) {
        url += `&${item}=${this.search_fields[item]}`;
      }
      if (this.isResubmit) {
        url += `&request_token=${this.request_token}`;
      }
      return url;
    },
    reSubmit() {
      this.isResubmit = true;
      this.submit();
    },
    submit() {
      if (!this.$store.getters.auth) {
        return this.$router.push("/login?next=/direct-search");
      }
      this.stop();
      this.clearErrors();
      this.$axios
        .get(`/search/direct/${this.get_url_params()}`)
        .then(res => {
          this.isResubmit = false;
          if (res.data && res.data.error_code === "140") {
            const data = res.data;
            let q = "";
            for (var item in this.search_fields) {
              if (this.search_type.search_type === "company_id"){
                q = q + " " + '"' + this.search_fields[item] + '"';
              } else {
                q = q + " " + this.search_fields[item];
              }
            }
            this.errors.rejected = res.data.error_description;
            this.errors.code = res.data.error_code;
            this.redirect_url = `/search?c=${data.country_code}&q=${q}`;
            this.is140 = true;
            // return this.$router.push(`/search?c=${data.country_code}&q=${q}`);
          } else if (res.data && res.data.error_code === "310") {
            this.errors.rejected =
              "Your query matched more than 3 records. Are you sure you want search within third party site?";
            this.isTimer = true;
            this.is140 = false;
            this.timerCount = res.data.expire_interval;
            this.request_token = res.data.request_token;
            this.play();

            const data = res.data;
            let q = "";
            for (var field in this.search_fields) {
              if (this.search_type.search_type === "company_id"){
                q = q + " " + '"' + this.search_fields[field] + '"';
              } else {
                q = q + " " + this.search_fields[field];
              }
            }
            this.redirect_url = `/search?c=${data.country_code}&q=${q}`;
          }
          if (res.data.response_status == "REJECTED") {
            // this.errors.rejected = res.data.error_description;
            this.errors.rejected = res.data.error_description;
            this.errors.code = res.data.error_code;
          } else if (res.data.response_status == "ACCEPTED") {
            this.$store.dispatch("direct_search", res.data);
            this.is140 = false;
            this.isTimer = false;
          } else {
            this.errors.search = true;
          }
        })
        .catch(err => {
          if (err.response) {
            this.errors.search = err.response.data;
          }
        });
    },
    cancelSearch() {
      this.$store.dispatch("cancel_direct_search");
    }
  }
};
</script>
<style scoped>
h3 {
  font-size: 20px;
  line-height: 1.3;
}
h3:hover,
.btn-result:hover,
.history-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
